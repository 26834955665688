exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-studio-js": () => import("./../../../src/pages/studio.js" /* webpackChunkName: "component---src-pages-studio-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-featured-work-js": () => import("./../../../src/templates/featured-work.js" /* webpackChunkName: "component---src-templates-featured-work-js" */),
  "component---src-templates-tax-application-js": () => import("./../../../src/templates/tax-application.js" /* webpackChunkName: "component---src-templates-tax-application-js" */),
  "component---src-templates-tax-discipline-js": () => import("./../../../src/templates/tax-discipline.js" /* webpackChunkName: "component---src-templates-tax-discipline-js" */),
  "component---src-templates-tax-sector-js": () => import("./../../../src/templates/tax-sector.js" /* webpackChunkName: "component---src-templates-tax-sector-js" */),
  "component---src-templates-tax-type-js": () => import("./../../../src/templates/tax-type.js" /* webpackChunkName: "component---src-templates-tax-type-js" */)
}

