/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';
import {AnimatePresence} from 'framer-motion';
import { elementScrollIntoViewPolyfill } from "seamless-scroll-polyfill";

export const wrapPageElement = ({element}) => (
  <AnimatePresence mode='wait'>{element}</AnimatePresence>
);

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const transitionDelay = 600;
  window.history.scrollRestoration = "manual";
  const currentPosition = getSavedScrollPosition(location);
  window.setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]));
  }, transitionDelay );
  return false;
};

export const onClientEntry = () => {
  elementScrollIntoViewPolyfill();
};
